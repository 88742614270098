import allergens from './allergens.json'
import chat from './chat.json'
import colors from './colors.json'
import defaults from './defaults.json'
import delivery from './delivery.json'
import errors from './errors.json'
import fields from './fields.json'
import filter from './filter.json'
import help from './help.json'
import material from './material.json'
import nutrients from './nutrients.json'
import old from './old.json'
import openingHours from './opening-hours.json'
import order from './order.json'
import payment from './payment.json'
import role from './role.json'
import stock from './stock.json'
import template from './template.json'
import themes from './themes.json'
import types from './types.json'
import unit from './unit.json'
import washAndCare from './wash-and-care.json'

const nl_NL = {
    ...old,
    ...defaults,
    ...material,
    ...allergens,
    ...washAndCare,
    ...nutrients,
    ...fields,
    ...help,
    ...order,
    ...stock,
    washAndCare,
    order,
    delivery,
    allergens,
    filter,
    themes,
    errors,
    chat,
    unit,
    role,
    stock,
    payment,
    types,
    colors,
    'opening-hours': openingHours,
    template,
    ...template,
}

export default nl_NL
